import * as React from "react";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CountedTextField } from "../../reusable";

export const MetaData = (props) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="metadata-content"
      id="metadata-header"
    >
      <Typography variant={"h5"}>
        {`Meta data${props.record.editable_metas ? "" : " (Not requested)"}`}
      </Typography>
      ,
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CountedTextField
            suggestedLength={50}
            maximumLength={150}
            suggestedWithin={10}
            multiline
            fullWidth
            source="meta_title"
            label={"Meta title"}
            initialValue=""
            disabled={!props.record.editable_metas && props.isFreelance}
          />
        </Grid>
        <Grid item xs={12}>
          <CountedTextField
            suggestedLength={150}
            maximumLength={400}
            suggestedWithin={10}
            multiline
            fullWidth
            source="meta_description"
            label={"Meta description"}
            initialValue=""
            disabled={!props.record.editable_metas && props.isFreelance}
          />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
);
