import React, { useEffect } from "react";
import { useNotify, useRecordContext } from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import copy from "copy-to-clipboard";
import { GridCloseIcon } from "@mui/x-data-grid";
import { Autocomplete } from "@material-ui/lab";

interface ActionsProps {
  label: string;
  UTMMediums: object;
  source: string;
  tooltip: string;
}

interface LinkCopyActionsProps {
  label: string;
  source: string;
  tooltip: string;
}

export const Actions: React.FC<ActionsProps> = ({
  label,
  UTMMediums,
  source,
  tooltip,
}) => {
  return (
    <>
      {/* <LinkCopyAction label={label} source={source} tooltip={tooltip} /> */}
      <UTMLinkAction UTMMediums={UTMMediums} />
    </>
  );
};

export const LinkCopyAction: React.FC<LinkCopyActionsProps> = ({
  label,
  source,
  tooltip,
}) => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleCopy = React.useCallback(() => {
    copy(record[source]);
    notify("Copied successfully");
  }, [record, source, notify]);

  return (
    <>
      {record[source] ? (
        <>
          <Tooltip title={`Copy ${tooltip}`}>
            <IconButton onClick={handleCopy}>
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
    </>
  );
};

export const UTMLinkAction = ({ UTMMediums }: { UTMMediums: any }) => {
  const [source, setSource] = React.useState<string>("");
  const [medium, setMedium] = React.useState<string>("");
  const [dialogOpen, openDialog] = React.useState<boolean>(false);
  const [prettyLink, setPrettyLink] = React.useState<string>("");

  const notify = useNotify();
  const record = useRecordContext();

  useEffect(() => {
    if (!record.pretty_link) return;
    setPrettyLink(record.pretty_link);
  }, [record.pretty_link]);

  const handleClear = React.useCallback(() => {
    setSource("");
    setMedium("");
    setPrettyLink(record.pretty_link);
  }, [record.pretty_link]);

  const handleSourceChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSource(e.target.value);

      setPrettyLink((prev) => {
        const url = new URL(prev);
        url.searchParams.set("utm_source", e.target.value);
        return url.toString();
      });
    },
    [setSource],
  );

  const handleMediumChange = React.useCallback(
    (e: React.ChangeEvent<{}>, value: any) => {
      const label = value ? (value.label as string) : "";
      setMedium(label);

      setPrettyLink((prev) => {
        const url = new URL(prev);
        url.searchParams.set("utm_medium", label);
        return url.toString();
      });
    },
    [setMedium],
  );

  const handleOpen = React.useCallback(() => {
    openDialog(true);
  }, [openDialog]);
  const handleClose = React.useCallback(() => {
    openDialog(false);
  }, [openDialog]);
  const handleCopy = React.useCallback(() => {
    copy(prettyLink);
    notify("Copied successfully");
  }, [prettyLink, notify]);

  if (!record.pretty_link) return null;

  return (
    <>
      <Tooltip title={"Create UTM Link"}>
        <IconButton onClick={handleOpen}>
          {/* <LinkIcon /> */}
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>Create UTM Link</DialogTitle>
        <DialogContent>
          <FormControl>
            <Autocomplete
              id="utm-medium"
              options={UTMMediums}
              getOptionLabel={(option: any) => option.display_name}
              onChange={handleMediumChange}
              selectOnFocus
              style={{ width: 300, margin: ".2rem" }}
              renderInput={(params) => (
                <TextField {...params} label="UTM Medium" variant="outlined" />
              )}
            />
            <TextField
              style={{ width: 300, margin: ".2rem" }}
              value={source}
              onChange={handleSourceChange}
              label="Source"
              variant="outlined"
            />
          </FormControl>
          <TextField
            style={{ marginTop: "0.4rem", marginBottom: "0.4rem" }}
            variant="outlined"
            disabled
            value={prettyLink}
            label="UTM Link"
            fullWidth
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<HighlightOffIcon />}
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileCopyIcon />}
            onClick={handleCopy}
          >
            Copy
          </Button>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<GridCloseIcon />} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
