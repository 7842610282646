// in src/App.js
import { createHashHistory } from "history";
import isEmpty from "lodash/isEmpty";
import createAdminStore from "./createAdminStore";
// import messages from './i18n';
import { actionedDataProvider } from "../../actionedDataProvider";
import { API_URL, RESOURCE } from "../../constants";
import { httpClient, patch, post } from "../../utils";
import { authProvider } from "../../../auth/authProvider";

// import defaultMessages from "ra-language-english";
// import polyglotI18nProvider from "ra-i18n-polyglot";
// dependency injection
// const i18nProvider = polyglotI18nProvider((locale) => {
//   // if (locale !== 'en') {
//   //     return messages[locale];
//   // }
//   return defaultMessages;
// });

export const history = createHashHistory();
export const _dataProvider = actionedDataProvider(API_URL, httpClient);

/**
 * This function creates a FormData with the requested data.
 * Some values might be an array, so those values have been
 * inserted under same `key` on the FormData.
 * The invoice should have the `File` instance, so only `File`
 * instance should be appended to the Form.
 */
const makeFormData = (data, rawFileField) => {
  const rawFileInfo = data[rawFileField];
  const formData = new FormData();
  for (const key in data) {
    if (!data[key]) continue;

    if (Array.isArray(data[key])) {
      for (const arrData of data[key]) {
        formData.append(`${key}`, arrData);
      }
    } else {
      formData.append(key, data[key]);
    }
  }

  if (rawFileInfo && rawFileInfo.rawFile) {
    formData.append(rawFileField, rawFileInfo.rawFile);
  }
  return formData;
};

const makeRequst = async (resource, params, method, rawFileField) => {
  const data = params.data;
  const formData = makeFormData(data, rawFileField);
  let response = null;
  if (method === "PATCH") {
    response = await patch(
      `${API_URL}/${resource}/${params.id}/`,
      formData,
      false,
    );
  } else {
    response = await post(`${API_URL}/${resource}/`, formData, false);
  }
  return {
    data: response.json,
  };
};

export const dataProvider = {
  ..._dataProvider,
  create: async (resource, params) => {
    /**
     * This is a custom dataProvider create action to send listing fee in a
     * formData with the invoice File.
     * We are using Multipart Parser on the backend, so the request should be
     * made with a FormData containing all the data along with the File.
     */
    if (resource === RESOURCE.listingFees) {
      const response = makeRequst(resource, params, "POST", "invoice");
      return response;
    } else if (resource === RESOURCE.brand_info) {
      const response = makeRequst(resource, params, "POST", "brand_logo");
      return response;
    } else {
      return _dataProvider.create(resource, params);
    }
  },
  update: async (resource, params) => {
    /**
     * This is a custom dataProvider update action to update listing fee in a
     * formData with the invoice File.
     */
    if (resource === RESOURCE.listingFees) {
      const response = makeRequst(resource, params, "PATCH", "invoice");
      return response;
    } else if (resource === RESOURCE.brand_info) {
      const response = makeRequst(resource, params, "PATCH", "brand_logo");
      return response;
    } else {
      return _dataProvider.update(resource, params);
    }
  },
  getList: async (resource, params) => {
    /**
     * This function is a custom implementation to change the filter query URL.
     * Generally, for `<SelectArrayInput />` React Admin sends the URL like:
     * https://igtm.app/api/listing_fee/?type=listing_fee&type=youtube_top
     * instead we want the URL to be:
     * https://igtm.app/api/listing_fee/?type=listing_fee,youtube_top
     * This will allow us to look for both type `listing_fee` & `youtube_top`
     * on the array of strings on the backend.
     */
    if (resource === RESOURCE.listingFees && !isEmpty(params.filter)) {
      let { listing_type, youtubes, websites } = params.filter;
      if (listing_type) {
        listing_type = listing_type.join(",");
      }
      if (websites) {
        websites = websites.join(",");
      }
      if (youtubes) {
        youtubes = youtubes.join(",");
      }

      params.filter = {
        ...params.filter,
        listing_type: listing_type,
        websites: websites,
        youtubes: youtubes,
      };
      return _dataProvider.getList(resource, params);
    } else if (resource === RESOURCE.deals) {
      const sort = params.sort;
      let field = sort.field;
      if (field && field !== "" && field.startsWith("-")) {
        field = field.slice(1);
        sort.field = field;
      }
      return _dataProvider.getList(resource, params);
    } else {
      return _dataProvider.getList(resource, params);
    }
  },
};

export const store = createAdminStore({
  authProvider,
  dataProvider,
  history,
});
