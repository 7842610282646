import * as React from "react";

import { makeStyles, IconButton, Tooltip, Typography } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import DoneAllIcon from "@material-ui/icons/DoneAll";

import SubjectIcon from "@material-ui/icons/Subject";
import {
  TextField,
  DateField,
  List,
  ShowButton,
  FunctionField,
  Filter,
  Loading,
  SelectArrayInput,
  usePermissions,
  SimpleList,
  TextInput,
  downloadCSV,
  SelectInput,
  EditButton,
  BooleanInput,
  useRecordContext,
} from "react-admin";
import jsonExport from "jsonexport/dist";

import { ContentListAside } from "./list/ContentListAside";
import { RESOURCE, STATUS } from "../constants";
import { WebsiteReferenceArrayInput } from "../website";
import { UserReferenceField } from "../user";
import { WriterReferenceArrayInput } from "../writer";
import {
  CONTENT_PRIORITY,
  CONTENT_PRIORITY_CHOICES,
  GROUPS,
} from "../constants";
import CustomizableDatagrid from "ra-customizable-datagrid/lib/CustomizableDatagrid";
import { useWriter } from "./writer/useWriter";
import { BulkActions } from "./list/BulkActions";
import { DateRangeDialog, todayISOString, getDayFromNow } from "../reusable";
import { useMediaQuery } from "@material-ui/core";
import { useTotalWordsNoForm } from "../reusable/store/countWords";
import { UserReferenceArrayInput } from "../user/inputs/UserReferenceArrayInput";

import WarningIcon from "@material-ui/icons/Warning";
import useGetRate from "./utils/useGetRate";

const time_to_integrate = (content_approved, published) => {
  if (content_approved && published) {
    const approved_date = new Date(content_approved);
    const published_date = new Date(published);
    const diff_in_days =
      (published_date - approved_date) / (1000 * 60 * 60 * 24);
    return diff_in_days.toFixed(2);
  }
  return "-";
};

const time_since_writing = (has_started_writing, published) => {
  if (has_started_writing) {
    const hasStartedWriting = new Date(has_started_writing);
    const hasStartedWritingDate = hasStartedWriting.toISOString().slice(0, 10);

    // the last date should be the content publication date
    let lastDate = new Date();
    if (published) {
      const published_date = new Date(published);

      if (published_date < lastDate) {
        lastDate = published_date;
      }
    }

    const diff_in_days = (
      (lastDate - hasStartedWriting) /
      (1000 * 60 * 60 * 24)
    ).toFixed(2);

    return (
      <>
        <p>{hasStartedWritingDate}</p>
        <p>
          ({diff_in_days} {published ? "Since Published" : "Days Ago"})
        </p>
      </>
    );
  }
  return "-";
};

export const ContentList = ({
  filter = {},
  hasShow = false,
  hasEdit = false,
  hasWriter = false,
  withAside = true,
  filterDefaultValues,
  defaultColumns,
  ...props
}) => {
  const { permissions } = props;

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  if (!permissions) {
    return <Loading />;
  }
  const freelance = permissions.groups.includes(GROUPS.freelances);
  const proofreader = permissions.groups.includes(GROUPS.proofreader);
  return (
    <List
      basePath={RESOURCE.order}
      resource={RESOURCE.order}
      hasShow={hasShow}
      hasEdit={hasEdit}
      exporter={exporter}
      title={" "}
      syncWithLocation={false}
      bulkActionButtons={<BulkActions />}
      {...props}
      filter={filter}
      aside={withAside ? <ContentListAside /> : null}
      filterDefaultValues={filterDefaultValues}
      filters={<Filters />}
      sort={{ field: "date_created", order: "DESC" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `${record.full_url}`}
          secondaryText={(record) =>
            `${record.status} - requested / written: ${record.requested_words} / ${record.total_words_written}`
          }
          tertiaryText={(record) => record.writer_name}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
        />
      ) : (
        <CustomizableDatagrid
          rowClick={hasEdit ? "edit" : hasShow ? "show" : null}
          defaultColumns={[
            "full_url",
            "requested_words",
            "total_words_written",
            !proofreader && "writer", // don't show writer when the user is proofreading
            "keyword",
            "Status",
            "Quote",
          ]}
          rowStyle={(record, index) => {
            let backgroundColor;
            if (record?.content_priority === CONTENT_PRIORITY.high) {
              backgroundColor = "#fee2e2";
            } else if (record?.content_priority === CONTENT_PRIORITY.normal) {
              backgroundColor = "#ffffff";
            } else if (record?.content_priority === CONTENT_PRIORITY.low) {
              backgroundColor = "#e6f7ff";
            } else if (record?.imported) {
              backgroundColor = "#ffeded";
            } else if (index % 2 !== 0) {
              backgroundColor = "#f8f8f8";
            } else {
              backgroundColor = "#fbfbfb";
            }
            return {
              backgroundColor,
            };
          }}
          mini={false}
        >
          <TextField source="id" />
          <TextField source="title" />
          <PagePath label="url" source="full_url" />

          <UserReferenceField source="created_by" label="Requested by" />
          {!freelance || !proofreader
            ? [
                <UserReferenceField
                  key="writer"
                  source="writer"
                  label="Writer"
                />,
              ]
            : []}

          <UserReferenceField source="approved_by" label="Approved By" />
          <UserReferenceField source="integrator" label="Integrator" />
          <FunctionField
            source="keyword"
            label="Main Keyword"
            render={(r) => r.keywords && r.keywords[0]}
          />
          <TextField source="requested_words" />
          <TextField
            source="words_written"
            sortable={false}
            label="Main Content"
            emptyText={"-"}
          />
          <TextField
            source="total_words_written"
            sortable={false}
            label="All Content"
            emptyText={"-"}
          />
          {/* Visibility: either is a writer, or is not any other type of freelancer */}
          {!freelance || permissions.groups.includes(GROUPS.writers) ? (
            <EstimatedQuoteField source="quote" label="quote" />
          ) : (
            <span />
          )}

          <TextField source="type" label="type" emptyText={"-"} />

          <DateField source="date_created" />
          {!freelance ? (
            <DateField source="published" label={"Date Published"} />
          ) : (
            <span />
          )}
          <DateField source="content_eta" label={"Deadline"} />
          <DateField source="integrator_eta" label={"Integrator Deadline"} />
          <DateField
            source="first_submission_to_review"
            label={"Submission Date"}
          />
          <DateField
            source="submission_to_review"
            label={"Last Submission Date"}
          />
          <DateField source="content_approved" label={"Approval Date"} />
          {/* Visibility: not any other type of freelancer */}
          {!freelance ? (
            <TextField source="pbn_promoted_url" label={"PBN Promoted URL"} />
          ) : (
            <span />
          )}
          {!freelance ? (
            <TextField
              // value={""}
              emptyText={""}
              source="pbn_content_title"
              label={"PBN Content Title"}
            />
          ) : (
            <span />
          )}
          <FunctionField
            source="content_paid"
            label="Days to Integrate"
            render={(record) =>
              time_to_integrate(record.content_approved, record.published)
            }
          />
          <FunctionField
            source="has_started_writing"
            label="Writer Started Writing"
            render={(record) =>
              time_since_writing(record.has_started_writing, record.published)
            }
          />
          <FunctionField
            source="content_paid"
            label="Content Paid"
            render={(record) => (record.content_paid ? <>✅</> : <>❌</>)}
          />
          <FunctionField
            source="status"
            label="Status"
            render={(record) =>
              record.status === STATUS.double_checked ? (
                <>
                  <CheckIcon />
                  <CheckIcon />
                </>
              ) : record.content_approved ? (
                <CheckIcon />
              ) : record.refusal_reason !== "" ? (
                <span>
                  <ClearIcon />
                  <RateReviewOutlinedIcon />{" "}
                </span>
              ) : record.status === STATUS.review ? (
                <RateReviewOutlinedIcon />
              ) : (
                <SubjectIcon />
              )
            }
          />
          {hasEdit ? <EditButton /> : <span />}
          {hasShow ? <ShowButton /> : <span />}
        </CustomizableDatagrid>
      )}
    </List>
  );
};

const exporter = async (stats, fetchRelatedRecords) => {
  const clearWriterName = (value) => {
    if (value) {
      return value.split("(")[0];
    }
    return "";
  };
  const clearDate = (value) => {
    if (value) {
      return value.split("T")[0];
    }
    return "";
  };
  const [creators, approvers, integrators, writers] = await Promise.all([
    fetchRelatedRecords(stats, "created_by", RESOURCE.user),
    fetchRelatedRecords(stats, "approved_by", RESOURCE.user),
    fetchRelatedRecords(stats, "integrator", RESOURCE.user),
    fetchRelatedRecords(stats, "writer", RESOURCE.writer),
  ]);
  const data = stats.map((entry) => ({
    Url: entry.full_url,
    Status: entry.status,
    "Requested by": creators[entry.created_by]?.full_name,
    "Approved by": approvers[entry.approved_by]?.full_name,
    Integrator: integrators[entry.integrator]?.full_name,
    Writer: clearWriterName(writers[entry.writer]?.full_name),
    "Date Requested": clearDate(entry.date_created),
    "Date Approved": clearDate(entry.content_approved),
    "Date Published": clearDate(entry.published),
    "Payment Date": clearDate(entry.content_paid),
    "Submission Date": clearDate(entry.first_submission_to_review),
    Deadline: clearDate(entry.content_eta),
    "Last Submission Date": clearDate(entry.submission_to_review),
    "Main Keyword": entry.guide,
    "Words Requested": entry.requested_words,
    "Words Written": entry.total_words_written,
    Cost: entry.content_quote,
    Type: entry.type,
    "Days to Integrate": time_to_integrate(
      entry.content_approved,
      entry.published,
    ),
    Priority: entry?.content_priority,
  }));
  jsonExport(
    data,
    {
      headers: [
        "Url",
        "Status",
        "Requested by",
        "Approved by",
        "Integrator",
        "Writer",
        "Date Requested",
        "Date Approved",
        "Date Published",
        "Payment Date",
        "Submission Date",
        "Deadline",
        "Words Requested",
        "Words Written",
        "Main Keyword",
        "Cost",
        "Type",
        "Days to Integrate",
        "Priority",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `content-export.${todayISOString()}`)
        : console.error(err),
  );
};

const useStyles = makeStyles((theme) => ({
  multipleExclude: {
    "& .MuiInputBase-root": {
      width: "16rem",
    },
    "& .MuiFormLabel-root": {
      color: "red",
    },
  },
  multipleInclude: {
    "& .MuiInputBase-root": {
      width: "16rem",
    },
    "& .MuiFormLabel-root": {
      color: "green",
    },
  },
  multiple: {
    "& .MuiInputBase-root": {
      width: "16rem",
    },
  },
}));

export const Filters = ({ ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles();

  const { permissions, loading } = usePermissions();
  if (loading || !permissions?.groups) {
    return <Loading />;
  }
  const freelance = permissions.groups.includes(GROUPS.freelances);
  return (
    <Filter {...props}>
      <TextInput source={"s"} label={"Search"} resettable alwaysOn />
      <WebsiteReferenceArrayInput
        source={"multiple_website"}
        resettable
        alwaysOn={!isSmall}
      />
      <DateRangeDialog
        dataTestid={"filter-created"}
        label={"Date Created"}
        sourceBefore={"before"}
        sourceAfter={"after"}
        startDate={getDayFromNow(-365)}
        alwaysOn={!isSmall}
      />
      <DateRangeDialog
        dataTestid={"filter-approved"}
        label={"Date Approved"}
        sourceBefore={"approved_end"}
        sourceAfter={"approved_start"}
        startDate={null}
        endDate={null}
        alwaysOn={!isSmall}
        resettable
      />
      <DateRangeDialog
        dataTestid={"filter-published"}
        label={"Date Published"}
        sourceBefore={"published_end"}
        sourceAfter={"published_start"}
        startDate={null}
        endDate={null}
        alwaysOn={!isSmall}
        resettable
      />
      <DateRangeDialog
        dataTestid={"filter-integrator_eta"}
        label={"Integrator Deadline"}
        sourceBefore={"integrator_eta_end"}
        sourceAfter={"integrator_eta_start"}
        startDate={null}
        endDate={null}
        alwaysOn={!isSmall}
        resettable
      />
      {!freelance
        ? [
            <SelectInput
              choices={[
                { id: "1", name: "✅ Content Paid" },
                { id: "2", name: "❌ Content Not Paid" },
              ]}
              label="Content Paid"
              emptyText={"All"}
              source="has_paid"
              resettable
              alwaysOn
            />,
            <WriterReferenceArrayInput
              source={"multiple_writer"}
              alwaysOn={!isSmall}
              resettable
            />,
            <UserReferenceArrayInput
              source={"multiple_created_by"}
              label={"Requested By"}
              // source="created_by"
              resettable
              alwaysOn={!isSmall}
              filter={{ groups__name: GROUPS.editors }}
            />,
            <UserReferenceArrayInput
              // key="approved by"
              label={"Approved By"}
              source={"multiple_approved_by"}
              alwaysOn={!isSmall}
              resettable
              withFreelances
              filter={{ groups__name__in: ["proofreaders", "editors"] }}
            />,
            <UserReferenceArrayInput
              // key="Integration assigned to"
              label={"Integrator"}
              source={"multiple_integrated_by"}
              alwaysOn={!isSmall}
              resettable
              filter={{ groups__name: GROUPS.integrators }}
            />,
            <SelectArrayInput
              className={classes.multiple}
              choices={CONTENT_PRIORITY_CHOICES}
              label="Order Priority"
              emptyText={"All"}
              source="content_priority__in"
              resettable
              alwaysOn
            />,
          ]
        : null}
      <SelectArrayInput
        className={classes.multipleInclude}
        choices={[
          { id: "review", name: "review" },
          { id: "optimization", name: "optimization" },
          { id: "linkbuilding", name: "linkbuilding" },
          { id: "video", name: "video" },
          { id: "pbn", name: "pbn" },
          { id: "other", name: "other" },
        ]}
        label="Multiple Order Type"
        emptyText={"All"}
        source="type__in"
        resettable
        alwaysOn
      />
      <SelectArrayInput
        className={classes.multipleExclude}
        choices={[
          { id: "review", name: "review" },
          { id: "optimization", name: "optimization" },
          { id: "linkbuilding", name: "linkbuilding" },
          { id: "video", name: "video" },
          { id: "pbn", name: "pbn" },
          { id: "other", name: "other" },
        ]}
        label="Exclude Order Type"
        emptyText={"All"}
        source="type__nin"
        resettable
        alwaysOn
      />
      {!freelance
        ? [
            <SelectArrayInput
              className={classes.multipleInclude}
              choices={[
                { id: STATUS.requested, name: "Requested" },
                { id: STATUS.review, name: "Review" },
                { id: STATUS.approved, name: "Approved" },
                { id: STATUS.integration, name: "Integrator Assigned" },
                { id: STATUS.published, name: "Published" },
                { id: STATUS.double_checked, name: "Double Checked" },
              ]}
              label="Multiple Order Status"
              emptyText={"All"}
              source="status__in"
              resettable
              alwaysOn
            />,
            <BooleanInput
              label="Writers currently writing"
              source="has_started_writing"
              alwaysOn
            />,
            <BooleanInput
              label="Late Contents"
              source="late_content"
              alwaysOn
            />,
          ]
        : null}
    </Filter>
  );
};

const PagePath = ({ record, source, item, ...props }) => {
  if (!record) return <span>-</span>;
  try {
    const url = new URL(record[source]);
    return (
      <a target="_blank" href={record[source]} rel="noreferrer noopener">
        {url.host}
        <wbr />
        {url.pathname}
      </a>
    );
  } catch (e) {
    return <span>{record[source] || "-"}</span>;
  }
};

export const useEstimate = (
  record,
  estimate = false,
  requested = false,
  limit = false,
) => {
  const recordContext = useRecordContext();
  if (!record) {
    record = recordContext;
  }
  const { loaded, error, data } = useWriter(record?.writer);
  const value = { quote: "", value: 0, currency: "" };
  const rate = useGetRate();
  const totalWords = useTotalWordsNoForm(record);
  const words = totalWords.totalWords;
  if (error) {
    return (
      <Tooltip title={`Error: ${error}`}>
        <ErrorIcon color={"action"} error={error} />
      </Tooltip>
    );
  }

  if (!loaded || !rate.loaded) {
    return <Loading />;
  }

  const actual = record.content_quote;

  // If there is an actual cost and the status is approved or more continue
  if (
    parseFloat(actual) &&
    ![STATUS.review, STATUS.requested].includes(record.status)
  ) {
    value.quote = `${actual} ${data?.currency}`;
  }

  // if the status is approved or more but there is no cost, return Error
  else if (
    !parseFloat(actual) &&
    ![STATUS.va_reviewed, STATUS.review, STATUS.requested].includes(
      record.status,
    )
  ) {
    value.quote = "ERROR!";
  }

  // the status is in request, or review, use an estimate
  else if (requested) {
    const estimate_ = ((totalWords.requested * rate.rate) / 100.0).toFixed(2);
    const withTemplatePrice =
      parseFloat(estimate_) + parseFloat(record.writer_template_rate);
    value.quote = `Cost: ${withTemplatePrice} ${data?.currency}`;
    value.currency = data?.currency;
    value.value = estimate_;
  }
  // the status is in request, or review, use an estimate
  else if (limit) {
    const estimate2 = ((words * rate.rate) / 100.0).toFixed(2);
    const estimate1 = (
      (totalWords.requested * 1.25 * rate.rate) /
      100.0
    ).toFixed(2);
    const estimate_ = Math.min(estimate1, estimate2);
    const withTemplatePrice =
      parseFloat(estimate_) + parseFloat(record.writer_template_rate);
    value.quote = `Cost: ${withTemplatePrice} ${data?.currency}`;
    value.currency = data?.currency;
    value.value = estimate_;
  } else if (estimate) {
    const estimate_ = ((words * rate.rate) / 100.0).toFixed(2);
    const withTemplatePrice =
      parseFloat(estimate_) + parseFloat(record.writer_template_rate);
    value.quote = `Cost: ${withTemplatePrice} ${data?.currency}`;
    value.currency = data?.currency;
    value.value = estimate_;
  } else {
    value.quote = "-";
  }
  return value;
};

export const EstimatedQuoteField = ({
  record,
  currency,
  estimate = false,
  ...props
}) => {
  /**
   * Returns the estimated quote for the content.
   */
  const value = useEstimate(record, estimate);
  return (
    <>
      <TextField source="quote" record={value} label="Quote" />
    </>
  );
};

export const TemplatePriceField = ({ record }) => {
  const hasTemplatePrice =
    record && parseFloat(record.writer_template_rate) !== 0.0;

  return hasTemplatePrice ? (
    <Tooltip
      title={
        <>
          <Typography fontSize={20}>
            Template Price {record.writer_template_rate} included
          </Typography>
        </>
      }
    >
      <IconButton color="primary">
        <WarningIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};
